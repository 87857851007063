import { WebViewerInstance } from '@pdftron/webviewer';
import { Fzf } from 'fzf';
import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { reloadMetaDossier } from 'cloudFunctions/functions.ts';
import i18n from 'i18n.ts';
import { queryClient } from 'queries';
import {
  closeDossierSession,
  createDossierSession,
} from 'queries/dossierSessions.ts';
import { tryAcquireRWLockAndMonitor } from 'queries/dossiersRWLocks.ts';
import { useAppState } from 'stores/appStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import {
  AnnotStatus,
  IDossierLock,
  TEntityVersionInfo,
  TInteractionsFS,
  TMetaDossierEntry,
  TMetaDossierResponse,
  TMetaDossierWipElByMetaPath,
  TSyncValue,
  TWipMetaEl,
} from 'types/index';
import { TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS } from 'utils/constants.ts';
import { Dossier } from 'utils/dossier.ts';
import { reorderDocumentPages } from 'utils/reorderDocumentPages.ts';
import { getReviewStatus } from 'utils/review/helpers.ts';
import { updateMetaDossierErrors } from 'utils/sync/checkErrors.ts';

const disableEditElementsAndFeatures = (instance: WebViewerInstance) => {
  const { disableFeatures, disableElements, Feature } = instance.UI;

  disableElements(TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS);
  disableFeatures([Feature.ThumbnailReordering, Feature.ThumbnailMerging]);
  instance.Core.annotationManager.enableReadOnlyMode();
};

const enableEditElementsAndFeatures = (instance: WebViewerInstance) => {
  const { enableFeatures, enableElements, Feature } = instance.UI;

  enableElements(TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS);
  enableFeatures([Feature.ThumbnailReordering, Feature.ThumbnailMerging]);
  instance.Core.annotationManager.disableReadOnlyMode();
};

interface InteractionsStateValues {
  dossier: Dossier | null;
  openedInRWMode: boolean | null;
  roModalOpen: boolean;
  lockInfo: IDossierLock | null;
  isInReadOnlyMode: boolean;
  isPermanentReadOnlyMode: boolean;
  entitiesStatuses: Map<string, AnnotStatus>;
  prevVersionData: Map<string, TEntityVersionInfo>;
  entitiesRejectionReasons: Map<string, string>;
  metaDossierInfoIsLoading: boolean;
  metaDossierInfo: TMetaDossierResponse | undefined;
  remoteReviewStatus: null | 'pending' | 'valid' | 'invalid';
  reviewStatus: null | 'pending' | 'valid' | 'invalid';
  remoteReviewFeedbackMarkdown: string | null;
  reviewFeedbackMarkdown: string | null;
  metaDossierWipElByMetaPath: TMetaDossierWipElByMetaPath;
  metaDossierChanges: Record<string, TSyncValue>;
  searchEntityValue: string;
  entitiesMatched: Set<string> | null;
}

interface InteractionsState extends InteractionsStateValues {
  getDataForFs: () => TInteractionsFS;
  mergeData: (data: TInteractionsFS) => void;
  setDossier: (dossier: Dossier | null) => void;
  getDossier: () => Promise<Dossier>;
  setOpenedInRWMode: (opened: boolean) => void;
  setRoModalOpen: (open: boolean) => void;
  setLockInfo: (lockInfo: IDossierLock) => void;
  enableReadOnlyMode: (permanent?: boolean) => void;
  disableReadOnlyMode: () => void;
  setEntityStatus: (
    id: string,
    targetStatus: AnnotStatus,
    force?: boolean
  ) => void;
  getEntityStatus: (id: string) => AnnotStatus;
  setEntityRejectionReason: (entityId: string, reason: string) => void;
  getEntityRejectionReason: (entityId: string) => string;
  setMetaDossierInfoIsLoading: (isLoading: boolean) => void;
  setMetaDossierInfo: (metaDossierInfo: TMetaDossierResponse) => void;
  setReviewStatus: (status: 'pending' | 'valid' | 'invalid') => void;
  setReviewFeedbackMarkdown: (markdown: string | null) => void;
  setMetaDossierWipEl: (metaPath: string, value: TSyncValue) => void;
  setMetaDossierChanges: (
    metaDossierChanges: Record<string, TSyncValue>
  ) => void;
  setSearchEntityValue: (value: string) => void;
}

const getDefaultStateValues = (): InteractionsStateValues => {
  console.info('Reseting interactions state');
  return {
    dossier: null as Dossier | null,
    openedInRWMode: null as boolean | null,
    isInReadOnlyMode: false as boolean,
    isPermanentReadOnlyMode: false as boolean,
    lockInfo: null as IDossierLock | null,
    prevVersionData: new Map(),
    entitiesStatuses: new Map(),
    metaDossierInfo: undefined as TMetaDossierResponse | undefined,
    metaDossierInfoIsLoading: true as boolean,
    remoteReviewStatus: null as null | 'pending' | 'valid' | 'invalid',
    reviewStatus: null as null | 'pending' | 'valid' | 'invalid',
    reviewFeedbackMarkdown: null as string | null,
    remoteReviewFeedbackMarkdown: null as string | null,
    entitiesRejectionReasons: new Map<string, string>(),
    metaDossierWipElByMetaPath: new Map(),
    metaDossierChanges: {},
    roModalOpen: false as boolean,
    searchEntityValue: '',
    entitiesMatched: null as Set<string> | null,
  };
};

export const useInteractionsState = create<InteractionsState>()(
  devtools(
    (set, get, store) => ({
      ...getDefaultStateValues(),
      getDataForFs: (): TInteractionsFS => {
        return {
          entitiesStatuses: Object.fromEntries(
            get().entitiesStatuses.entries()
          ),
          entitiesRejectionReasons: Object.fromEntries(
            get().entitiesRejectionReasons.entries()
          ),
        };
      },
      mergeData(data: TInteractionsFS) {
        set(() => ({
          entitiesStatuses: new Map([
            ...get().entitiesStatuses.entries(),
            ...Object.entries(data.entitiesStatuses || {}),
          ]),
          entitiesRejectionReasons: new Map([
            ...get().entitiesRejectionReasons.entries(),
            ...Object.entries(data.entitiesRejectionReasons || {}),
          ]),
        }));

        if (data.prevVersion?.data) {
          set(() => ({
            prevVersionData: new Map(Object.entries(data.prevVersion!.data)),
          }));
        }
      },
      setDossier: (dossier: Dossier | null) => {
        set(() => ({ dossier }));
      },
      getDossier: async () => {
        if (get().dossier) {
          return get().dossier!;
        }
        return new Promise((resolve) => {
          const unsubscribe = store.subscribe((state) => {
            if (state.dossier) {
              unsubscribe();
              resolve(state.dossier! as Dossier);
            }
          });
        });
      },
      setOpenedInRWMode: (opened: boolean) => {
        set(() => ({ openedInRWMode: opened }));
      },
      setRoModalOpen: (open: boolean) => {
        set(() => ({ roModalOpen: open }));
      },
      setLockInfo: (lockInfo: IDossierLock) => {
        set(() => ({ lockInfo: lockInfo }));
      },
      enableReadOnlyMode: (permanent: boolean = false) => {
        const wasPermanent = get().isPermanentReadOnlyMode;
        set(() => ({
          isInReadOnlyMode: true,
          isPermanentReadOnlyMode: wasPermanent ? true : permanent,
        }));
        const instance = useAppState.getState().instance;
        if (instance) {
          disableEditElementsAndFeatures(instance);
        }

        if (permanent) {
          // No changes to be saved anymore
          useAppState.getState().setHasEntitiesRelatedChangesToSave(false);
        }
      },
      disableReadOnlyMode: () => {
        if (get().isPermanentReadOnlyMode) {
          console.log("Cannot disable read-only mode as it's permanent");
          return;
        }
        if (useUiState.getState().isWorkflowLoading) {
          console.log(
            'Cannot disable read-only mode as workflow is loading, it will be disabled after'
          );
          return;
        }

        set(() => ({ isInReadOnlyMode: false }));
        const instance = useAppState.getState().instance;
        if (instance) {
          enableEditElementsAndFeatures(instance);
        }
      },

      getEntityStatus: (id: string) => {
        return get().entitiesStatuses.get(id) || AnnotStatus.PENDING;
      },
      // We allow to force the status to be set, to bypass intermediate PENDING state
      // When using keyboard we cannot jump from VALIDATED to REJECTED or vice versa
      // But when we click on the UI we want to force the generation of the new status
      setEntityStatus: (
        id: string,
        targetStatus: AnnotStatus,
        force: boolean = false
      ) => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }
        if (!useUiState.getState().documentFullyLoaded) {
          enqueueSnackbar(i18n.t('validation.docNotFullyLoaded'), {
            variant: 'info',
          });
          return;
        }
        const prev = get().entitiesStatuses;
        // All statuses might not be set, by default they will be in PENDING
        const currentStatus = prev.get(id) || AnnotStatus.PENDING;
        if (currentStatus === targetStatus) {
          return;
        }

        const next = new Map(prev);

        if (
          force ||
          targetStatus === AnnotStatus.PENDING ||
          currentStatus === AnnotStatus.PENDING
        ) {
          next.set(id, targetStatus);
        } else {
          if (targetStatus === AnnotStatus.VALIDATED) {
            switch (currentStatus) {
              case AnnotStatus.VALIDATED:
                break;
              case AnnotStatus.REJECTED:
                next.set(id, AnnotStatus.PENDING);
                break;
              default:
                break;
            }
          } else if (targetStatus === AnnotStatus.REJECTED) {
            switch (currentStatus) {
              case AnnotStatus.VALIDATED:
                next.set(id, AnnotStatus.PENDING);
                break;
              case AnnotStatus.REJECTED:
                break;
              default:
                break;
            }
          } else {
            throw new Error('Invalid status');
          }
        }
        set(() => ({
          entitiesStatuses: next,
        }));
        useAppState.getState().setHasEntitiesRelatedChangesToSave(true);
      },
      setEntityRejectionReason: (entityId: string, reason: string) => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }
        const prev = get().entitiesRejectionReasons;
        const next = new Map(prev);
        next.set(entityId, reason);
        set(() => ({
          entitiesRejectionReasons: next,
        }));
        useAppState.getState().setHasEntitiesRelatedChangesToSave(true);
      },
      getEntityRejectionReason: (entityId: string) => {
        return get().entitiesRejectionReasons.get(entityId) || '';
      },
      setMetaDossierInfoIsLoading: (isLoading: boolean) => {
        set(() => ({ metaDossierInfoIsLoading: isLoading }));
      },
      setMetaDossierInfo: (metaDossierInfo: TMetaDossierResponse) => {
        // We also update the metaDossierWipElByMetaPath elements
        const prev = get().metaDossierWipElByMetaPath;
        const next = new Map(prev);

        const metaEntries: TMetaDossierEntry[] = [
          ...Object.values(metaDossierInfo.metaDossierData.benef),
          ...Object.values(metaDossierInfo.metaDossierData.chantier),
          ...Object.values(metaDossierInfo.metaDossierData.company),
          ...Object.values(metaDossierInfo.metaDossierData.operations).flatMap(
            (op) => Object.values(op.data)
          ),
        ];

        for (const metaEntry of metaEntries) {
          if (!next.has(metaEntry.internalPath)) {
            // we init the key
            next.set(metaEntry.internalPath, {
              metaPath: metaEntry.internalPath,
              hasBeenEdited: false,
              label: metaEntry.label,
              type: metaEntry.type,
              value: metaEntry.value,
              remoteValue: metaEntry.value,
              errors: [],
              isReadOnlyStation: metaEntry.isReadOnlyStation,
              dynamicRules: metaEntry.dynamicRules,
              stringOptions: metaEntry.stringOptions,
            });
          } else {
            // we only update the remote value
            next.set(metaEntry.internalPath, {
              ...next.get(metaEntry.internalPath)!,
              remoteValue: metaEntry.value,
            });
          }
        }

        const newMetaPaths = new Set(metaEntries.map((e) => e.internalPath));

        for (const metaPath in [...next.keys()]) {
          if (!newMetaPaths.has(metaPath)) {
            // we clean previous data
            next.delete(metaPath);
          }
        }

        const remoteReviewStatus = getReviewStatus(metaDossierInfo.review);
        set(() => ({
          metaDossierInfo,
          metaDossierWipElByMetaPath: next,
          remoteReviewStatus: remoteReviewStatus,
          reviewStatus: get().reviewStatus ?? remoteReviewStatus,
          remoteReviewFeedbackMarkdown:
            metaDossierInfo.review.reviewFeedbackMarkdown ?? '',
          reviewFeedbackMarkdown:
            get().reviewFeedbackMarkdown ??
            metaDossierInfo.review.reviewFeedbackMarkdown ??
            '',
        }));
        updateMetaDossierErrors();
      },
      setReviewStatus: (status: 'pending' | 'valid' | 'invalid') => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }

        set(() => ({ reviewStatus: status }));
      },
      setReviewFeedbackMarkdown: (markdown: string | null) => {
        set(() => ({ reviewFeedbackMarkdown: markdown }));
      },
      setMetaDossierChanges: (
        metaDossierChanges: Record<string, TSyncValue>
      ) => {
        // we prevent useless updates
        if (!isEqual(metaDossierChanges, get().metaDossierChanges)) {
          set({ metaDossierChanges });
        }
      },
      setMetaDossierWipEl: (metaPath: string, value: TSyncValue) => {
        console.log('changeRequested', { metaPath, value });
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }

        const prev = get().metaDossierWipElByMetaPath;
        const next = new Map(prev);

        const info = prev.get(metaPath)!;

        next.set(metaPath, {
          ...info,
          value,
          hasBeenEdited: true,
        });

        set(() => ({ metaDossierWipElByMetaPath: next }));
        updateMetaDossierErrors();
      },
      setSearchEntityValue(value: string) {
        set(() => ({ searchEntityValue: value }));
      },
    }),
    {
      name: 'interactions-store',
    }
  )
);

const getName = (state: InteractionsState) => {
  const firstName =
    state.metaDossierInfo?.metaDossierData?.benef?.firstName?.value;
  const lastName =
    state.metaDossierInfo?.metaDossierData?.benef?.lastName?.value;
  if (firstName && lastName) {
    return `${firstName} ${lastName} - ${state.dossier?.name ?? ''}`;
  }
  return state.dossier?.name ?? '';
};

useInteractionsState.subscribe((state, prevState) => {
  if (state.dossier && state.dossier.id !== prevState.dossier?.id) {
    const dossier = state.dossier;
    Promise.all([
      useAppState.getState().getInstance(),
      useAppState.getState().downloadViewedPdf(dossier),
    ]).then(async ([instance, rawPdfBytes]) => {
      const { PDFNet } = instance.Core;
      const { loadDocument, openElements, closeElements } = instance.UI;
      const rawPdfDocument = await PDFNet.PDFDoc.createFromBuffer(
        Uint8Array.from(rawPdfBytes)
      );
      let reorderedPdf = rawPdfDocument;
      if (!dossier.disableAutoReorder) {
        reorderedPdf = await reorderDocumentPages(
          rawPdfDocument,
          dossier.pageNumbersMappingForReordering!,
          instance
        );
      }

      loadDocument(reorderedPdf, {
        filename: dossier.fileName,
        extension: 'pdf',
        enableOfficeEditing: false,
      });

      if (dossier.nbDocuments > 1) {
        openElements(['outlinesPanel']);
      } else {
        closeElements(['outlinesPanel']);
      }
    });
  }

  let metaDossierChanges: Record<string, TSyncValue> = {};
  if (
    state.metaDossierWipElByMetaPath !== prevState.metaDossierWipElByMetaPath
  ) {
    for (const [key, info] of state.metaDossierWipElByMetaPath.entries()) {
      if (info.value !== info.remoteValue) {
        metaDossierChanges[key] = info.value;
      }
    }

    useInteractionsState.getState().setMetaDossierChanges(metaDossierChanges);
  } else {
    metaDossierChanges = state.metaDossierChanges;
  }

  const hasCRMChanges =
    Object.keys(metaDossierChanges).length > 0 ||
    state.reviewStatus !== state.remoteReviewStatus ||
    state.reviewFeedbackMarkdown !== state.remoteReviewFeedbackMarkdown;
  if (
    hasCRMChanges !== useAppState.getState().hasCRMDossierRelatedChangesToSave
  ) {
    useAppState.getState().setHasCRMDossierRelatedChangesToSave(hasCRMChanges);
  }

  if (
    state.dossier !== prevState.dossier ||
    state.metaDossierInfo !== prevState.metaDossierInfo
  ) {
    document.title = getName(state);
  }
});

useInteractionsState.subscribe((state, prevState) => {
  if (state.searchEntityValue !== prevState.searchEntityValue) {
    if (state.searchEntityValue === '') {
      useInteractionsState.setState({ entitiesMatched: null });
    } else {
      if (state.dossier) {
        const searchableEntities = state.dossier.searchEntitiesData.flatMap(
          (el) => {
            const links =
              state.metaDossierInfo?.metaDossierLinkByAnnotId[el.id] ?? [];
            const syncElements = links
              .map((link) =>
                state.metaDossierWipElByMetaPath.get(link.metaDossierDataPath)
              )
              .filter((el) => el !== undefined) as TWipMetaEl[];

            return [
              el,
              ...syncElements.map((syncEl) => ({
                id: el.id,
                label: syncEl.label,
              })),
            ];
          }
        );

        // Could be optimized with some caching here but nah
        const fzf = new Fzf(searchableEntities, {
          selector: (item) => `${item.label}`,
        });
        const finds = fzf.find(state.searchEntityValue);
        useInteractionsState.setState({
          // We don't handle sort, it would be a bit complicated to reorder the entites as they are displayed
          entitiesMatched: new Set(finds.map((f) => f.item.id)),
        });
      }
    }
  }
});

const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  const hasChangesToSave = useAppState.getState().hasChangesToSave;
  if (hasChangesToSave) {
    e.preventDefault();
  }

  const dossierId = useAppState.getState().urlParams.dossierId;
  if (dossierId) {
    closeDossierSession({
      dossierId,
      sessionId: useAppState.getState().sessionId,
      releaseRwLock: !useInteractionsState.getState().isPermanentReadOnlyMode,
    });
  }
};

window.addEventListener('beforeunload', handleBeforeUnload);

useAppState.subscribe(async (state, prevState) => {
  const prevDossierId = prevState.urlParams?.dossierId;
  const newDossierId = state.urlParams?.dossierId;

  if (newDossierId !== prevDossierId) {
    if (prevDossierId) {
      closeDossierSession({
        dossierId: prevDossierId,
        sessionId: useAppState.getState().sessionId,
        releaseRwLock: !useInteractionsState.getState().isPermanentReadOnlyMode,
      });
      useInteractionsState.getState().setDossier(null);
    }
    if (newDossierId) {
      createDossierSession().then((ref) =>
        useAppState.getState().setSessionId(ref.id)
      );
    }

    if (
      newDossierId &&
      typeof state.urlParams.chantierId === 'undefined' &&
      (await useAppState.getState().getCompany()).featureFlags
        ?.handleBackofficeSync
    ) {
      console.time('Reloading meta dossier');
      useInteractionsState.getState().setMetaDossierInfoIsLoading(true);
      reloadMetaDossier({
        mode: 'fromDossierId',
        dossierId: newDossierId,
      }).then(() => {
        console.timeEnd('Reloading meta dossier');
        queryClient.invalidateQueries({
          queryKey: ['dossier', 'meta'],
        });
      });
    }

    const newValues = getDefaultStateValues();
    useInteractionsState.setState(newValues);
    if (newDossierId) {
      tryAcquireRWLockAndMonitor(
        useAppState.getState().getDossierId(),
        await useAppState.getState().getUser()
      );
    }
  }

  if (state.instance !== prevState.instance) {
    if (state.instance) {
      if (useInteractionsState.getState().isInReadOnlyMode) {
        useInteractionsState.getState().enableReadOnlyMode();
      } else {
        useInteractionsState.getState().disableReadOnlyMode();
      }
    }
  }
});
