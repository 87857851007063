import { FieldValue } from '@firebase/firestore';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { Mutex } from 'async-mutex';
import { isEqual } from 'lodash';

import {
  DossierStatus,
  IDocTypesByFileByPage,
  IDossierWithAnalysis,
  IEntityForDisplay,
  TAnalysisResponse,
  WorkflowStatus,
} from 'types/index';
import { reDrawBoundingBBoxAndAddMissingHighlights } from 'utils/annotations.ts';
import { buildOutline, updateOutlineSelected } from 'utils/bookmarks.ts';
import { docTypeOrderInOutline } from 'utils/constants.ts';
import {
  getDossierGcsFileName,
  getDossierInputFolderPath,
} from 'utils/dossierHelpers.ts';
import { getDocExtraLabelByDocKey } from 'utils/entities.ts';
import {
  getPageNumbersMapping,
  remapAnnotationsPages,
  remapDocTypesByFileByPage,
} from 'utils/reorderDocumentPages.ts';

/**
 * Helper class to manage all dossier related operation & data
 *
 * It stores everything from a firestore document and also the entities.
 * It handles page modification tracking, etc.
 */
export class Dossier implements IDossierWithAnalysis {
  readonly size?: number | undefined;
  readonly companyId: string;
  readonly createdAt: FieldValue;
  readonly updatedAt?: FieldValue;
  readonly createdBy: string;
  readonly id: string;
  readonly isUniqueDevis: boolean;
  readonly name: string;
  readonly tagId: string;
  readonly workflowExecutions: number;
  readonly workflowStatus?: WorkflowStatus;
  readonly docTypesByFileByPage: IDocTypesByFileByPage;
  readonly disableAutoReorder: boolean;
  readonly pdfFileVersion?: number;
  readonly metadataIsFromWorkflow: boolean;
  readonly status: DossierStatus;
  readonly archived?: boolean | null;
  readonly archivedAt?: FieldValue | null;
  readonly archivedBy?: string | null;
  readonly codesAdeme: string[];
  displayedPageNumberByWorkflowPageNumber?: Record<string, number>;

  readonly analysis: TAnalysisResponse;
  readonly pageNumbersMappingForReordering?: Map<number, number>;
  remappedDocTypesByFileByPage: IDocTypesByFileByPage = {};
  remappedEntities: IEntityForDisplay[] = [];
  docExtraLabelByDocKey: Record<string, string> = {};
  private onMetadataUpdatedMutex: Mutex = new Mutex();
  readonly searchEntitiesData: { id: string; label: string }[];

  readonly _initalizedAt: string;

  /**
   * Array of nulls or numbers.
   *
   * If at idx i there is a number k, it means that page k of the original PDF that was processed by the workflow
   * is now page i of the current PDF.
   *
   * If at idx i there is null, it means that page i was not present in the original PDF.
   * idx 0 of this array is always null. This is because the first page of a PDF is always at index 1.
   */
  private entitiesPageNumbersTracker: (number | null)[];

  constructor(dossier: IDossierWithAnalysis) {
    this.companyId = dossier.companyId;
    this.createdAt = dossier.createdAt;
    this.createdBy = dossier.createdBy;
    this.id = dossier.id;
    this.status = dossier.status;
    this.isUniqueDevis = dossier.isUniqueDevis;
    this.name = dossier.name;
    this.tagId = dossier.tagId;
    this.workflowExecutions = dossier.workflowExecutions;
    this.updatedAt = dossier.updatedAt;
    this.workflowStatus = dossier.workflowStatus;
    this.docTypesByFileByPage = dossier.docTypesByFileByPage ?? {};
    this.disableAutoReorder = dossier.disableAutoReorder ?? false;
    this.metadataIsFromWorkflow = dossier.metadataIsFromWorkflow ?? true;
    this.archived = dossier.archived;
    this.archivedAt = dossier.archivedAt;
    this.archivedBy = dossier.archivedBy;
    this.codesAdeme = dossier.codesAdeme ?? [];
    this.pdfFileVersion = dossier.pdfFileVersion;
    this.displayedPageNumberByWorkflowPageNumber =
      dossier.displayedPageNumberByWorkflowPageNumber;

    this._initalizedAt = new Date().toISOString();

    this.analysis = dossier.analysis;

    if (!this.disableAutoReorder) {
      // We setup the data for reordering
      this.pageNumbersMappingForReordering = getPageNumbersMapping(
        this.docTypesByFileByPage,
        docTypeOrderInOutline
      );

      // In this case we have a 1:1 mapping between the workflow page number and the displayed page number
      this.displayedPageNumberByWorkflowPageNumber = Object.fromEntries(
        this.pageNumbersMappingForReordering.entries()
      );
    } else {
      // otherwise,
      // We have some mix of metadata, we need to remap some things :tada:
      this.pageNumbersMappingForReordering = undefined;

      if (this.metadataIsFromWorkflow) {
        // If the metadata is from the workflow, we build a simple mapping between pages
        this.displayedPageNumberByWorkflowPageNumber = Object.fromEntries(
          Object.values(this.docTypesByFileByPage)
            .flatMap((el) => Object.values(el))
            .flat()
            .map((el) => [el, el])
        );
      } else {
        // We reuse the data from firestore (that was previously saved by the frontend)
        this.displayedPageNumberByWorkflowPageNumber =
          dossier.displayedPageNumberByWorkflowPageNumber!;
      }
    }

    // We cannot store number as keys in a javascript object, so we have to parse the keys
    const entitiesPageMapping: Map<number, number> = new Map(
      Object.entries(this.displayedPageNumberByWorkflowPageNumber).map(
        ([k, v]) => [parseInt(k), v]
      )
    );

    // We initialize the page Tracker (see field documentation above)
    // from an empty array of the size of the number of pages in the current PDF.
    // We fill it with nulls (as if all pages had change)
    this.entitiesPageNumbersTracker = new Array(
      (entitiesPageMapping.size ? Math.max(...entitiesPageMapping.keys()) : 0) +
        1
    ).fill(null);
    // And then we fill from the mapping
    for (const [workflowPage, displayedPage] of entitiesPageMapping.entries()) {
      this.entitiesPageNumbersTracker[displayedPage] = workflowPage;
    }
    // Finally we refresh the other metadata based on the page mapping
    this.refreshMetadata(entitiesPageMapping);

    this.searchEntitiesData = [
      ...this.analysis.extractedEntities,
      ...this.analysis.postProcessedEntities,
      ...Object.values(this.analysis.missingFields)
        .flatMap((el) => Object.values(el))
        .flat(),
    ].map((entity) => ({ id: entity.id, label: entity.label }));
  }

  /**
   * Refresh the metadata based on the page mapping
   * (entities, docTypesByFileByPage, docExtraLabelByDocKey)
   */
  refreshMetadata(entitiesPageMapping: Map<number, number>) {
    this.remappedDocTypesByFileByPage = remapDocTypesByFileByPage(
      this.docTypesByFileByPage,
      entitiesPageMapping
    );

    this.remappedEntities = remapAnnotationsPages(
      this.analysis.extractedEntities,
      entitiesPageMapping
    );

    this.docExtraLabelByDocKey = getDocExtraLabelByDocKey(
      this.docTypesByFileByPage,
      this.analysis.extractedEntities
    );
  }

  get hasUnAnylizedPages(): boolean {
    return (
      // > 1 because the first entry is always null
      this.entitiesPageNumbersTracker.filter((el) => el === null).length > 1
    );
  }

  async refreshUI(instance: WebViewerInstance) {
    // We run the refreshUI in a mutex to avoid async issues with all the
    // async elements in the UI
    await this.onMetadataUpdatedMutex.runExclusive(async () => {
      const { Core } = instance;
      const { documentViewer } = Core;

      if (!documentViewer?.getDocument()) return;

      // In all cases we rebuild the outline and re-draw the annotations
      await buildOutline(
        instance,
        this.remappedDocTypesByFileByPage,
        this.docExtraLabelByDocKey
      );
      await reDrawBoundingBBoxAndAddMissingHighlights(
        this.remappedEntities,
        instance
      );

      // We don't await this because it can be slow
      updateOutlineSelected(
        documentViewer.getDocument(),
        documentViewer.getCurrentPage()
      );
    });
  }

  /**
   * Track PDF changes from the Document Viewer and trigger a refresh of the metadata
   */
  async trackPdfChanges(
    changes: Core.DocumentViewer.pagesUpdatedChanges,
    instance: WebViewerInstance
  ): Promise<boolean> {
    const newEntitiesPageNumbersTracker = trackPdfChanges(
      changes,
      this.entitiesPageNumbersTracker
    );
    const hasChanges = !isEqual(
      newEntitiesPageNumbersTracker,
      this.entitiesPageNumbersTracker
    );
    this.entitiesPageNumbersTracker = newEntitiesPageNumbersTracker;

    // We need to recompute the entities & metadata to avoid out of sync entities,
    // for instance if we delete a page, we need to remove the entities on this page,
    // otherwise if we add it again the old entities will show up :scream:
    this.refreshMetadata(
      new Map(
        Object.entries(this.updatedDisplayedPageNumberByWorkflowPageNumber).map(
          ([k, v]) => [parseInt(k), v]
        )
      )
    );
    if (hasChanges) {
      await this.refreshUI(instance);
    }
    return hasChanges;
  }

  get updatedDisplayedPageNumberByWorkflowPageNumber(): Record<string, number> {
    const out = Object.fromEntries(
      this.entitiesPageNumbersTracker
        .map((originalPageNumber, currentPageNumber) => [
          originalPageNumber,
          currentPageNumber,
        ])
        .filter((el) => el[0] !== null)
    );
    return out;
  }

  get entitiesPageNumbersTrackerCopy(): (number | null)[] {
    return this.entitiesPageNumbersTracker.slice();
  }

  get nbDocuments(): number {
    return [...Object.values(this.docTypesByFileByPage)]
      .map((el) => Object.keys(el).length)
      .reduce((a, b) => a + b, 0);
  }

  get fileName(): string {
    return `${this.name}.pdf`;
  }

  getGcsFileName(
    newVersion?: number,
    options: { withExtension: boolean } = { withExtension: true }
  ): string {
    return getDossierGcsFileName(
      this.id,
      newVersion ?? this.pdfFileVersion,
      options
    );
  }

  get gcsInputFolderPath() {
    return getDossierInputFolderPath(this.companyId, this.id);
  }
}

/**
 * Check if the elements of the array form a contiguous set of integers.
 * [1, 3, 2] is contiguous
 * [1, 3] is not contiguous
 */
const checkArrayIsContiguous = (arr: number[]): boolean => {
  const base = [...new Set(arr)].sort((a, b) => a - b);
  if (base.length !== arr.length) {
    return false;
  }
  if (Math.max(...base) - Math.min(...base) !== base.length - 1) {
    return false;
  }
  return true;
};

/**
 * Helper method to track the changes in the PDF pages to know where to put the annotations
 */
export const trackPdfChanges = (
  changes: Core.DocumentViewer.pagesUpdatedChanges,
  pageNumbersTracker: (number | null)[]
): (number | null)[] => {
  const outPageNumbersTracker = pageNumbersTracker.slice();

  if (changes.added.length > 0) {
    // We can only handle if pages are added contiguously
    if (!checkArrayIsContiguous(changes.added)) {
      console.error('Cannot handle the changes', changes);
      throw new Error('Cannot handle non contiguous pages');
    }

    changes.added.forEach((page) => {
      outPageNumbersTracker.splice(page, 0, null);
    });

    const maxPageNumber = Math.max(...changes.added) - changes.added.length + 1;
    if (maxPageNumber >= outPageNumbersTracker.length) {
      outPageNumbersTracker.length = maxPageNumber + 1;
      outPageNumbersTracker.fill(null, pageNumbersTracker.length);
    }
  }
  if (changes.removed.length > 0) {
    const maxPageNumber = Math.max(...changes.removed);
    if (maxPageNumber >= outPageNumbersTracker.length) {
      outPageNumbersTracker.length = maxPageNumber + 1;
      outPageNumbersTracker.fill(null, pageNumbersTracker.length);
    }

    changes.removed
      // we reverse sort to handle the changes from the end of the array and
      // make the splice work properly
      .sort((a, b) => b - a)
      .forEach((page) => {
        outPageNumbersTracker.splice(page, 1);
      });
  }

  if (
    changes.moved &&
    // moved is also mentionned if there has been removed and added,
    // this can be buggy so we take into account moved only if there is no added or removed
    changes.removed.length == 0 &&
    changes.added.length == 0
  ) {
    const maxPageNumber = Math.max(
      // @ts-ignore
      ...Object.entries(changes.moved)
        .map(([k, v]) => [parseInt(k), v])
        .flat()
    );
    if (maxPageNumber >= outPageNumbersTracker.length) {
      outPageNumbersTracker.length = maxPageNumber + 1;
      outPageNumbersTracker.fill(null, pageNumbersTracker.length);
    }

    Object.entries(changes.moved as Record<string, number>).forEach(
      ([originalPageNumberString, newPageNumber]) => {
        const originalPageNumber = parseInt(originalPageNumberString);
        outPageNumbersTracker[newPageNumber] =
          pageNumbersTracker[originalPageNumber] || null;
      }
    );
  }
  return outPageNumbersTracker;
};
