import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/joy';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/joy/CircularProgress';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { Fzf } from 'fzf';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getMetaDossierInfo } from 'cloudFunctions/functions.ts';
import { EditSyncElement } from 'components/Sync/SyncEntityEl.tsx';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import {
  TMetaDossierEntry,
  TMetaDossierResponse,
  TMetaDossierWipElByMetaPath,
  TSyncableTypes,
  TSyncValue,
  TWipMetaEl,
} from 'types/index';
import { isoDateStrToShortFrDateStr } from 'utils/dates.ts';

const dossierIdWithDataQuery = () => {
  const queryFunction = async (): Promise<TMetaDossierResponse | null> => {
    const dossierId = useAppState.getState().getDossierId();

    try {
      useInteractionsState.getState().setMetaDossierInfoIsLoading(true);
      const { data } = await getMetaDossierInfo({ dossierId });
      if (data) {
        useInteractionsState.getState().setMetaDossierInfo(data);
      }
      return data;
    } finally {
      useInteractionsState.getState().setMetaDossierInfoIsLoading(false);
    }
  };

  return queryOptions({
    queryKey: ['dossier', 'meta'],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};

type TSyncInfo = {
  metaPath: string;
  label: string;
  oldValue: TSyncValue;
  newValue: TSyncValue;
  type: TSyncableTypes;
  newValueErrors: string[];
  wipInfo: TWipMetaEl;
};

type TComputeInfo = {
  chantier: TSyncInfo[];
  company: TSyncInfo[];
  benef: TSyncInfo[];
  ops: Record<string, { code: string; syncInfo: TSyncInfo[] }>;
  opsKeysSorted: string[];
};

const formatValue = (
  value: TSyncValue,
  type: TSyncableTypes,
  color?: 'success' | 'error'
) => {
  if (value === null) {
    return (
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <Typography
          level={'body-xs'}
          // @ts-ignore
          color={color ?? 'neutral'}
        >
          (Vide)
        </Typography>
      </Stack>
    );
  }
  switch (type) {
    case 'date-time':
      return (
        <Typography level={'body-xs'} alignItems={'center'}>
          {isoDateStrToShortFrDateStr(value as string)}
        </Typography>
      );
    case 'boolean':
      return value ? (
        <CheckIcon color={'success'} fontSize={'small'}></CheckIcon>
      ) : (
        <CloseIcon color={'warning'} fontSize={'small'}></CloseIcon>
      );
    default:
      return (
        <Typography level={'body-xs'} alignItems={'center'}>
          {value}
        </Typography>
      );
  }
};

export function SyncPanelEntry({
  label,
  oldValue,
  newValue,
  newValueErrors,
  type,
  wipInfo,
}: TSyncInfo) {
  const { t } = useTranslation();
  const color = newValueErrors.length > 0 ? 'error' : 'success';
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const setMetaFieldBeingEdited = useUiState(
    (state) => state.setMetaFieldBeingEdited
  );
  const metaFieldBeingEdited = useUiState(
    (state) => state.metaFieldBeingEdited
  );

  const isEditing = metaFieldBeingEdited === wipInfo.metaPath;

  return (
    <Stack
      className={'sync-panel-entry'}
      flexDirection={'row'}
      alignItems={'center'}
      //gap={4}
      justifyContent={'space-between'}
      useFlexGap
      sx={{
        cursor: isInReadOnlyMode ? 'default' : 'pointer',
        flexWrap: 'wrap',
      }}
      onClick={() =>
        wipInfo.metaPath === metaFieldBeingEdited
          ? setMetaFieldBeingEdited(null)
          : setMetaFieldBeingEdited(wipInfo.metaPath)
      }
    >
      {isEditing && (
        <Box onClick={(e) => e.stopPropagation()}>
          <EditSyncElement el={wipInfo}></EditSyncElement>
        </Box>
      )}

      {!isEditing && (
        <Typography level={'body-sm'}>
          <b>{label}</b>
        </Typography>
      )}

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
      >
        {oldValue === newValue ? (
          !isEditing && formatValue(oldValue, type)
        ) : (
          <Stack flexDirection={'row'} gap={2} sx={{ flexGrow: 1 }}>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <AutoDeleteIcon fontSize={'small'}></AutoDeleteIcon>
              <div>{formatValue(oldValue, type)}</div>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <FiberNewOutlinedIcon
                color={'primary'}
                // @ts-ignore
              ></FiberNewOutlinedIcon>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={0.5}
                // @ts-ignore
                color={color}
              >
                {formatValue(newValue, type, color)}{' '}
                {newValueErrors.length > 0 ? t('sync.invalidExtra') : ''}
              </Stack>
            </Stack>
          </Stack>
        )}

        {!isEditing && (
          <IconButton
            size="sm"
            onClick={() => setMetaFieldBeingEdited(wipInfo.metaPath)}
            disabled={isInReadOnlyMode}
            sx={{
              visibility: 'hidden',
              '.sync-panel-entry:hover &': {
                visibility: 'visible',
              },
            }}
          >
            <EditIcon> </EditIcon>
          </IconButton>
        )}
      </Stack>
      {newValueErrors.length > 0 &&
        newValueErrors.map((error) => (
          <Typography key={error} level={'body-xs'} color={'danger'}>
            {error}
          </Typography>
        ))}
    </Stack>
  );
}

const computeDisplayInfo = (
  metaDossierRes: TMetaDossierResponse | null | undefined,
  metaDossierWipElByMetaPath: TMetaDossierWipElByMetaPath,
  editsOnly: boolean = true,
  searchValue: string = ''
): TComputeInfo => {
  const out: TComputeInfo = {
    chantier: [],
    benef: [],
    company: [],
    ops: {},
    opsKeysSorted: [],
  };

  if (!metaDossierRes) {
    return out;
  }

  let dataByPath = metaDossierWipElByMetaPath;
  if (searchValue) {
    const searchableValues = [...dataByPath.entries()].map(
      ([metaPath, info]) => ({ metaPath, label: info.label })
    );
    const fzf = new Fzf(searchableValues, {
      selector: (item) => item.label,
    });
    const finds = fzf.find(searchValue);
    const foundMetaPaths = new Set(finds.map((f) => f.item.metaPath));
    dataByPath = new Map(
      [...dataByPath.entries()].filter(([metaPath]) =>
        foundMetaPaths.has(metaPath)
      )
    );
  }

  for (const [metaPath, info] of dataByPath.entries()) {
    const metaDossierEntry = get(metaDossierRes.metaDossierData, metaPath) as
      | TMetaDossierEntry
      | undefined;
    if (!metaDossierEntry) {
      continue;
    }

    const infoCleaned: TSyncInfo = {
      metaPath,
      label: metaDossierEntry.label,
      oldValue: info.remoteValue,
      newValue: info.value,
      newValueErrors: info.errors,
      type: metaDossierEntry.type,
      wipInfo: info,
    };
    if (
      metaPath.startsWith('chantier.') ||
      metaPath.startsWith('benef.') ||
      metaPath.startsWith('company.')
    ) {
      // metaPath will be like 'chantier.<field>' or 'benef.<field>' or 'company.<field>'
      const obj = metaPath.split('.')[0] as 'chantier' | 'benef' | 'company';
      if (!editsOnly || infoCleaned.oldValue !== infoCleaned.newValue) {
        out[obj].push(infoCleaned);
      }
    } else {
      // metaPath will be like 'operations.<rowIdEncoded>.data.<field>
      const rowIdEncoded = metaPath.split('.')[1];
      if (!out.ops[rowIdEncoded]) {
        out.ops[rowIdEncoded] = {
          code: metaDossierRes.metaDossierData.operations[rowIdEncoded]
            .codeAdeme,
          syncInfo: [],
        };
      }
      if (!editsOnly || infoCleaned.oldValue !== infoCleaned.newValue) {
        out.ops[rowIdEncoded].syncInfo.push(infoCleaned);
      }
    }
  }

  out.opsKeysSorted = [...Object.keys(out.ops)];
  out.opsKeysSorted.sort(
    (a, b) =>
      out.ops[a].code.localeCompare(out.ops[b].code) || a.localeCompare(b)
  );

  return out;
};

export function SyncPanel() {
  const { t } = useTranslation();

  const [editsOnly, setEditsOnly] = useState(true);

  const metaDossierInfoIsLoading = useInteractionsState(
    (state) => state.metaDossierInfoIsLoading
  );
  const metaDossierWipElByMetaPath = useInteractionsState(
    (state) => state.metaDossierWipElByMetaPath
  );

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  useEffect(() => {
    if (isInReadOnlyMode) {
      useUiState.getState().setMetaFieldBeingEdited(null);
    }
  }, [isInReadOnlyMode]);

  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    setSearchValue('');
    setEditsOnly(!showSearch);
  }, [showSearch]);

  const { data: metaDossierRes } = useQuery(dossierIdWithDataQuery());

  const infos = useMemo<TComputeInfo>(
    () =>
      computeDisplayInfo(
        metaDossierRes,
        metaDossierWipElByMetaPath,
        searchValue ? false : editsOnly,
        searchValue
      ),
    [metaDossierRes, metaDossierWipElByMetaPath, editsOnly, searchValue]
  );

  const [expanded, setExpanded] = useState(false);

  const filteredOps = infos.opsKeysSorted.filter(
    (opKey) => !editsOnly || infos.ops[opKey]!.syncInfo.length > 0
  );

  if (!metaDossierRes) {
    return <Typography>{t('sync.noMetaDossier')}</Typography>;
  }

  return (
    <Card
      sx={{
        borderRadius: 12,
        borderWidth: 4,
        p: 2,
        pt: 0,
        pb: 1,
        width: '100%',
        overflowY: expanded ? 'auto' : 'unset',
        flexGrow: expanded ? 1 : 0,
        flexBasis: 1,
        maxHeight: 'fit-content',
      }}
    >
      <Accordion onChange={(_, newExpanded) => setExpanded(newExpanded)}>
        <AccordionSummary
          sx={{
            paddingTop: 2,
            paddingBottom: 1,
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#0b0d0e',
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ width: '100%' }}
          >
            <Typography
              level={'title-md'}
              sx={{ fontWeight: 'var(--joy-fontWeight-lg) !important' }}
              textAlign={'center'}
            >
              {t('ui.tabs.sync')}
            </Typography>
            {metaDossierInfoIsLoading ? (
              <Stack gap={1} direction={'row'} alignItems={'center'}>
                <Typography level={'body-sm'}>
                  {t('sync.dataUpdateInProgress')}
                </Typography>
                <CircularProgress size={'sm'} />
              </Stack>
            ) : (
              <Typography level={'body-xs'}>
                {t('sync.dataIsUpToData')}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          style={{
            // We set the visibility to 0 to make checkElementIsVisible work properly
            opacity: expanded ? 'unset' : 0,
          }}
        >
          <Stack gap={1}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <IconButton onClick={() => setShowSearch((v) => !v)}>
                <SearchIcon />
              </IconButton>
              {showSearch && (
                <Input
                  sx={{ flexGrow: 1 }}
                  autoFocus={true}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              )}
            </Stack>

            {(['benef', 'company', 'chantier'] as const)
              .filter((obj) => !editsOnly || infos[obj].length > 0)
              .map((obj) => (
                <Box
                  key={obj}
                  sx={{
                    mb: 1,
                    display:
                      searchValue && infos[obj].length === 0 ? 'none' : 'unset',
                  }}
                >
                  <Typography level={'title-sm'} sx={{ mb: 1 }}>
                    {t(`sync.${obj}SectionTitle`)}
                  </Typography>
                  <Card>
                    {infos[obj].length === 0 ? (
                      <Typography>
                        {editsOnly ? t('sync.noEdits') : t('sync.noInfo')}
                      </Typography>
                    ) : (
                      infos[obj].map((el) => (
                        <Box key={el.metaPath}>
                          <SyncPanelEntry {...el} />
                        </Box>
                      ))
                    )}
                  </Card>
                </Box>
              ))}

            {filteredOps.length > 0 && (
              <Stack direction={'column'} gap={1}>
                <Typography level={'title-sm'} sx={{ mb: 0.5, mt: 1 }}>
                  {t('sync.opsSectionTitle')}
                </Typography>
                {filteredOps.map((opKey) => (
                  <Card key={opKey}>
                    <Typography level={'title-md'} sx={{ mb: 0.5 }}>
                      🛠️ {infos.ops[opKey].code}
                    </Typography>
                    {infos.ops[opKey]!.syncInfo.length === 0 ? (
                      <Typography>
                        {editsOnly ? t('sync.noEdits') : t('sync.noInfo')}
                      </Typography>
                    ) : (
                      infos.ops[opKey]!.syncInfo.map((el) => (
                        <Box key={el.metaPath}>
                          <SyncPanelEntry {...el} />
                        </Box>
                      ))
                    )}
                  </Card>
                ))}
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
